<template>
  <div class="iss-main-grid">
    <div class="top-header-nav">
      <a-menu v-model:selectedKeys="current" mode="horizontal"  :style="{ borderBottom: 'none' }">
        <a-menu-item v-for="item in headerNavList" :key="item.key">
          {{ item.label }}
        </a-menu-item>
      </a-menu>
      <div>
       <recharge v-if="current[0]=='recharge'"></recharge>
       <sub-scription v-else-if="current[0]=='subscription'"></sub-scription>
      </div>
    </div> 
  </div>
</template>

<script>
import { reactive,  toRefs } from 'vue';
import {  Menu } from 'ant-design-vue';

import recharge from './components/recharge.vue';
import subScription from './components/subScription.vue';

export default {
  components: {
    AMenu: Menu,
    AMenuItem: Menu.Item,
    recharge,
    subScription
  },
  setup() {
    const states = reactive({
      current: ['subscription'],
      headerNavList: [
        {
          label: '订阅套餐',
          key: 'subscription',
        },
        {
          label: '金币充值',
          key: 'recharge',
        },
      ],
    });
    return {
      ...toRefs(states),
    };
  },
};
</script>

<style scoped lang="less">

/deep/ .ant-menu{
  background-color: transparent;
}
/deep/ .ant-table-body{
    max-height: 70vh !important;
  }
</style>
